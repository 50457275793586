<template>
  <div class="device-card" :class="[{bg: showBg}]">
    <div class="card-title">{{ detail.name }}</div>
    <div class="device-info-box">
      <div class="label-value-list">
        <div class="label-value-item">
          <div class="label-img-text">
            <img class="img" src="./img/icon_cpmc.png">
            <div class="label-text">产品名称：</div> 
          </div>
          <div class="value-text">{{ detail.productName }}</div>
        </div>
        <div class="label-value-item">
          <div class="label-img-text">
            <img class="img" src="./img/icon_jcz.png">
            <div class="label-text">监测值：</div> 
          </div>
          <div class="value-text">{{ detail.value }}</div>
        </div>
      </div>
      <div class="on-line-status" :class="[{lx: detail.statusName === '离线'},{zx: detail.statusName === '在线'}]">{{ detail.statusName }}</div>
    </div>
    <div class="data-up-time">数据上传时间：{{ detail.updateTime }}</div>
    <!-- 压力：数据延迟约15分钟，请谨慎处理     液位：数据每半天更新一次，请谨慎处理 -->
    <div class="tip-text">
      {{ detail.productName ? detail.productName.includes('压力') ? '数据延迟约15分钟，请谨慎处理' : detail.productName.includes('液位') ? '数据每半天更新一次，请谨慎处理' : '' : '' }}
    </div>
  </div>
</template>

<script>
import { onLineStatusMap } from './map.js';

export default {
  name: 'deviceCard',
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    showBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      onLineStatusMap
    };
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.device-card {
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(227,227,227,0.8);
  border-radius: 16px;
  .card-title {
    box-sizing: border-box;
    width: 100%;
    height: 84px;
    border-radius: 16px 16px 0px 0px;
    background-color: #F7FCFF;
    padding: 0px 30px;
    font-size: 24px;
    line-height: 84px;
    color: #797D7F;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .device-info-box {
    box-sizing: border-box;
    padding: 20px 30px 0px;
    display: flex;
    justify-content: space-between;
    .label-value-list {
      box-sizing: border-box;
      flex-grow: 1;
      .label-value-item {
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        .label-img-text {
          box-sizing: border-box;
          flex-shrink: 0;
          width: 220px;
          display: flex;
          align-items: center;
          .img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }
          .label-text {
            font-size: 28px;
            line-height: 28px;
            color: #797D7F;
          }
        }
        .value-text {
          box-sizing: border-box;
          font-size: 28px;
          line-height: 36px;
          padding-top: 13px;
          color: #1A1C34;
        }
      }
    }
    .on-line-status {
      box-sizing: border-box;
      margin-top: 20px;
      flex-shrink: 0;
      font-size: 24px;
      line-height: 24px;
      margin-left: 10px;
      &::before {
        content: '';
        box-sizing: border-box;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 10px;
      }
      &.zx {
        color: #3FDB6F;
        &::before {
          background-color: #3FDB6F;
        }
      }
      &.lx {
        color: #868686;
        &::before {
          background-color: #868686;
        }
      }
    }
  }
  .data-up-time {
    box-sizing: border-box;
    color: #797D7F;
    font-size: 24px;
    line-height: 26px;
    padding: 0px 32px 20px;
  }
  .tip-text {
    box-sizing: border-box;
    color:red;
    font-size: 20px;
    padding: 0px 0px 20px 30px;
  }
  &.bg {
    background-image: url('./img/bg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
  }
}
</style>
