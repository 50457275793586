import { mapHelper } from '@/utils/utils.js';

// 在线状态
const onLineStatus = [
  { value: 1, text: "在线" },
  { value: 2, text: "离线" },
];
const { map: onLineStatusMap, setOps: setOnLineStatusOps } = mapHelper.setMap(onLineStatus);

export {
  onLineStatus,
  onLineStatusMap,
  setOnLineStatusOps,
};
