<template>
  <div class="lo-t-monitor-list">
    <device-card
      v-for="(item, index) in list"
      :key="'sblp' + index"
      :detail="item"
    />
  </div>
</template>

<script>
import deviceCard from "./deviceCard.vue";
import { getLoTMonitorListUrl } from "./api.js";

export default {
  name: "loTMonitorList",
  components: {
    deviceCard,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get(getLoTMonitorListUrl, {
          params: {
            // deviceSN: this.$route.query.paramId || void 0,
            queryMode: "qrmode",
            pageSize: 50,
          },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records)
              ? res.data.records
              : [];
            res.data.records.forEach((item) => {
              item.name = item.deviceName + "（" + item.deviceNum + "）";
            });
            this.list = res.data.records;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.lo-t-monitor-list {
  min-height: 100%;
  background-color: #fafafa;
  box-sizing: border-box;
  padding: 20px 30px;
  .device-card {
    margin-bottom: 20px;
  }
}
</style>
